<template>
  <v-form>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 style="font-weight: 800; color: #FF0000">การจัดส่ง</h1>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มการจัดส่ง</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headerslogistic"
            :items="items"
            :search="search"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <!-- <template v-slot:body="props" >
              <draggable
                :list="props.items"
                tag="tbody"
                @change="DragItems(props.items)"
              >
                <tr v-for="(item, index) in props.items" :key="index">
                  <td>
                    <v-icon small class="page__grab-icon">
                      mdi-arrow-all
                    </v-icon>
                  </td>
                  <td class="text-center">{{ item.sortIndex }}</td>
                  <td class="text-center">
                    <img :src="item.logisticImg" width="100px" class="ma-1" />
                  </td>
                  <td class="text-center">{{ item.logisticName }}</td>
                  <td class="text-center">{{ item.description }}</td>
                  <td class="text-center">
                    <v-chip
                      class="orange lighten-2"
                      v-if="item.activeFlag == true"
                      >ใช้งาน</v-chip
                    >
                    <v-chip
                      class="grey lighten-9 white--text"
                      v-if="item.activeFlag == false"
                      >ไม่ใช้งาน</v-chip
                    >
                  </td>
                  <td class="text-center">
                    <v-row justify="center">
                      <v-icon @click="Viewlogistic(item)">mdi-eye</v-icon>
                      <v-icon @click="Updatelogistic(item)" class="mx-2"
                        >mdi-pencil</v-icon
                      >
                      <v-icon @click="Deletelogistic(item)">mdi-delete</v-icon>
                    </v-row>
                  </td>
                </tr>
              </draggable>
            </template> -->
            <template v-slot:[`item.logisticImg`]="{ item }">
              <v-img :src="item.logisticImg" width="120px" class="ma-1"></v-img>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip class="orange lighten-2" v-if="item.status == true"
                >ใช้งาน</v-chip
              >
              <v-chip
                class="grey lighten-9 white--text"
                v-if="item.status == false"
                >ไม่ใช้งาน</v-chip
              >
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="Viewlogistic(item)">mdi-eye</v-icon>
                <v-icon @click="Updatelogistic(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="Deletelogistic(item)">mdi-delete</v-icon>
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
export default {
  components: {
    draggable,
    Loading,
  },
  data() {
    return {
      headerslogistic: [
        // { text: "", value: "", align: "center", width: "50" },
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "ชื่อ", value: "name", align: "center" },
        { text: "ใช้งาน", value: "status", align: "center" },
        // { text: "created date", value: "description", align: "center" },
        { text: "หมายเหตุ", value: "note", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      items: [],
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
    };
  },
  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("userHealthAdmin")));
    this.getAlllogistic();
  },
  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userHealthAdmin"))
      );
      const auth = {
        headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E` },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/logistics/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAlllogistic() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/shippings`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAlllogisticpe", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      // console.log("itemBefore", this.items);
      // console.log('itemAfter',this.items);
    },
    Updatelogistic(val) {
      localStorage.setItem("logisticdata", Encode.encode(val));
      this.$router.push("Editlogistic");
    },
    Viewlogistic(val) {
      localStorage.setItem("logisticdata", Encode.encode(val));
      this.$router.push("Viewlogistic");
    },
    async Deletelogistic(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/shippings/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAlllogistic();
        }
      });
    },
    goToCreate() {
      this.$router.push("createlogistic");
    },
  },
};
</script>
