<template>
  <div>
    <ManageLogistic />
  </div>
</template>
<script>
import ManageLogistic from "@/components/Logistic/ManageLogistic";
export default {
  components: {
    ManageLogistic,
  },
  created() {
  },
};
</script>
